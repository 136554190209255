import { Button, Flex, Icon, Text, userInterfaceNotificationsStore } from "@engaging-tech/components"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  vertical-align: middle;
  font-style: italic;
`
const StyledButton = styled(Button)`
  background: none;
  box-shadow: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  :active {
    text-decoration: none;
    cursor: pointer;
  }
`

const VoiceAward = ({ t }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const shareContent = Object.values(t("welcome_slide.share_content", { returnObjects: true }, ""))

  const copyToClipboard = () => {
    if (history.location.pathname.includes("/in-app-view")) {
      navigator.clipboard.writeText("https://app.workl.com/in-app-view/happiness-survey/rank-company")
    } else {
      navigator.clipboard.writeText("https://app.workl.com/happiness-survey/rank-company")
    }
  }

  const handleOnclick = () => {
    copyToClipboard()
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Copied to clipboard"
      })
    )
  }
  return (
    <Flex flexDirection="column" bg="secondary.3" width="100%" p={3}>
      <Flex width={1 / 1} justifyContent="center" pb={3}>
        <StyledImg
          src="https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/companylogos/WHW-Logo.png"
          alt="Happiest Companies Awards"
          width="230px"
        />
      </Flex>
      <Flex flexDirection="column" px={3}>
        <Text color="primary.0" fontWeight="700" mb={4} textAlign="center">
          {shareContent[0]}
        </Text>
        <Text color="dark.2" mb={3}>
          {shareContent[1]}
        </Text>
        <Text color="dark.2" mb={3}>
          {shareContent[2]}
        </Text>
        <Flex width={1 / 1} justifyContent="flex-end">
          <StyledButton
            textAlign="right"
            onClick={() => {
              if (typeof window !== "undefined" && window.gtag) {
                window.gtag("event", "copy_survey_link", {
                  event_category: "button_click",
                  event_label: "Copy Link"
                })
              }
              handleOnclick()
            }}
          >
            <Icon name="link" pr="11px" />
            {t("welcome_slide.copy_link", "")}
          </StyledButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default VoiceAward
